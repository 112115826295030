/** @format */

import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Auth/Login";

// Role CRUD
import AddEditRole from "./pages/Role_Management/AddEditRole";
import PlatinumAddEditRole from "./pages/KingPlatinum/Role_Management/AddEditRole";
import RoleList from "./pages/Role_Management/RoleList";
import PlatinumRoleList from "./pages/KingPlatinum/Role_Management/RoleList";
import ProductPriceList from "./pages/Product_Price_Management/ProductPriceList";
import AddEditProduct from "./pages/Product_Price_Management/AddEditProduct";
import DOMAINURL from "./pages/DomainUrl";
import AIMKODOMAINURL from "./pages/KingPlatinum/DomainUrl";
import AIMKOCONTACTDETAIL from "./pages/KingPlatinum/ContactDetail";
import AMIKOUPDATEVERSION from "./pages/KingPlatinum/Role_Management/UpdateVersion";
import CONTACTDETAIL from "./pages/ContactDetail";
import UPDATEVERSION from "./pages/UpdateVersion";
import SAMIKODOMAINURL from "./pages/ShahidAmikoThousandTwo/DomainUrl";
import SAMIKOCONTACTDETAIL from "./pages/ShahidAmikoThousandTwo/ContactDetail";
import SAMIKODEVICEINFO from "./pages/ShahidAmikoThousandTwo/DeviceInfo";
import SAMIKOEditNOTE from "./pages/ShahidAmikoThousandTwo/EditNote";
import SAMIKOUPDATEVERSION from "./pages/ShahidAmikoThousandTwo/UpdateVersion";
import SMASADOMAINURL from "./pages/ShahidMasa/DomainUrl";
import SMASACONTACTDETAIL from "./pages/ShahidMasa/ContactDetail";
import SMASAUPDATEVERSION from "./pages/ShahidMasa/UpdateVersion";
import SMASADEVICEINFO from "./pages/ShahidMasa/DeviceInfo";
import MASADEVICEINFONOTE from "./pages/ShahidMasa/EditNote";
import SHTVDOMAINURL from "./pages/ShahidSHTV/DomainUrl";
import SHTVCONTACTDETAIL from "./pages/ShahidSHTV/ContactDetail";
import SHTVUPDATEVERSION from "./pages/ShahidSHTV/UpdateVersion";
import SHTVDEVICEINFO from "./pages/ShahidSHTV/DeviceInfo";
import SHTVDEVICEINFONOTE from "./pages/ShahidSHTV/EditNote";
import NotFound from "./pages/Page404";
import Protected from "./components/Protected";


// ----------------------------------------------------------------------

export default function Router() {
	return useRoutes([
		{
			path: "/",
			element: <Login />,
		},
		{
			path: "/",
			element: <Protected Component={Login} />,
		},
		{
			path: "/",
			element: <DashboardLayout />,
			children: [
				{ path: "device-info", element: <Protected Component={RoleList} /> },
				{
					path: "add-role-list",
					element: <Protected Component={AddEditRole} />,
				},
				{
					path: "device-info-note/:id",
					element: <Protected Component={AddEditRole} />,
				},
				{ path: "platinum-device-info", element: <Protected Component={PlatinumRoleList} /> },
				{
					path: "add-role-list",
					element: <Protected Component={AddEditRole} />,
				},
				{
					path: "platinum-device-info-note/:id",
					element: <Protected Component={PlatinumAddEditRole} />,
				},
				{
					path: "portal-list",
					element: <Protected Component={ProductPriceList} />,
				},
				{
					path: "add-portal",
					element: <Protected Component={AddEditProduct} />,
				},
				{
					path: "edit-portal/:id",
					element: <Protected Component={AddEditProduct} />,
				},
				{
					path: "domain_url",
					element: <Protected Component={DOMAINURL} />,
				},
				{
					path: "contact_detail",
					element: <Protected Component={CONTACTDETAIL} />,
				},
				{
					path: "update_version",
					element: <Protected Component={UPDATEVERSION} />,
				},
				{
					path: "amiko_domain_url",
					element: <Protected Component={AIMKODOMAINURL} />,
				},
				{
					path: "amiko_contact_detail",
					element: <Protected Component={AIMKOCONTACTDETAIL} />,
				},
				{
					path: "amiko_update_version",
					element: <Protected Component={AMIKOUPDATEVERSION} />,
				},
				{
					path: "Samiko_domain_url",
					element: <Protected Component={SAMIKODOMAINURL} />,
				},
				{
					path: "Samiko_contact_detail",
					element: <Protected Component={SAMIKOCONTACTDETAIL} />,
				},
				{
					path: "Samiko_device_info",
					element: <Protected Component={SAMIKODEVICEINFO} />,
				},
				{
					path: "Samiko_update_version",
					element: <Protected Component={SAMIKOUPDATEVERSION} />,
				},
				{
					path: "Samiko-device-info-note/:id",
					element: <Protected Component={SAMIKOEditNOTE} />,
				},
				{
					path: "update_version",
					element: <Protected Component={UPDATEVERSION} />,
				},
				{
					path: "shahid_masa_domain_url",
					element: <Protected Component={SMASADOMAINURL} />,
				},
				{
					path: "shahid_masa_contact_detail",
					element: <Protected Component={SMASACONTACTDETAIL} />,
				},
				{
					path: "shahid_masa_update_version",
					element: <Protected Component={SMASAUPDATEVERSION} />,
				},
				{
					path: "shahid_masa_device_info",
					element: <Protected Component={SMASADEVICEINFO} />,
				},
				{
					path: "masa-device-info-note/:id",
					element: <Protected Component={MASADEVICEINFONOTE} />,
				},
				{
					path: "shtv_domain_url",
					element: <Protected Component={SHTVDOMAINURL} />,
				},
				{
					path: "shtv_contact_detail",
					element: <Protected Component={SHTVCONTACTDETAIL} />,
				},
				{
					path: "shtv_update_version",
					element: <Protected Component={SHTVUPDATEVERSION} />,
				},
				{
					path: "shtv_device_info",
					element: <Protected Component={SHTVDEVICEINFO} />,
				},
				{
					path: "shtv-device-info-note/:id",
					element: <Protected Component={SHTVDEVICEINFONOTE} />,
				},

				
			],
		},
		{
			path: "/",
			element: <LogoOnlyLayout />,
			children: [
				{ path: "login", element: <Login /> },
				{ path: "404", element: <NotFound /> },
			],
		},
		{ path: "*", element: <Navigate to="/404" replace /> },
	]);
}
