import React,{Component} from 'react';
import axios from 'axios';
import toast from "react-hot-toast";
import {
	Grid,
	Card,
    Box,
    Button,
    FormControl,
	CardHeader, 
} from "@mui/material";
import Page from "../../components/Page";
import TextField from '@mui/material/TextField';
import DoneIcon from "@mui/icons-material/Done";
import FileUpload from '../../components/FileUpload'
import { deleteFile } from '../../store/services/platinumServices'
import CROSS from '../../assets/images/delete.png'

class DomainUrl extends Component
{
    state = {
      panels:[],
      isLoaded:true,
      url:'',
      portal_url:'',
      loading:false,
      error_list:[],
    }
    handleInput = (e) => {
      this.setState({
           [e.target.name]: e.target.value
      });
    }


    async componentDidMount() {
      const resp = await axios.get('https://smartkidstories.com/api/Samiko/domain_Url');
      console.log("Original",resp.data);
      const responde = resp.data
      this.setState({url:responde.url,portal_url:responde.portal_url});
    }

    updatePanels = async (e) => {
        e.preventDefault();
        let param = {
          app_url: this.state.app_url,
          portal_url: this.state.portal_url,
        }
        const formData = new FormData();
        Object.keys(param).map(p => {
          formData.append(p,this.state[p]);
        })
        formData.append('_method','put');
        formData.append('id',1);
        const id = 1;
          const res = await axios.post(`https://smartkidstories.com/api/Samiko/domainUrl/${id}`, formData);
          console.log("submit the data",res);
          if(res.data.status === 200)
        {
            toast.success("Update Domain Url is Successfully!");
        }
        else if(res.data.status === 400)
        {
               this.setState({
                ...this.state,
                   error_list:res.data.errors
               })
        }
  
  
        

  }

  
   render()
   {
    
    return(
     <>
		<Page className="User Overview" title="Shahid Amiko 2022 Domain Url">
            <Grid container spacing={2}>
					<Grid item xs={12}>
                    <Card className="RoleCard">
						<CardHeader title="Shahid Amiko 2022 Domain Url" className="RolePageHeading" />
                        <Grid container spacing={2} className="RoleCardBody">
							<Grid item xs={12}>
        
                     <form onSubmit={this.updatePanels}>
                     <Box className="BoxWidth">
                     <TextField id='outlined-basic' 
                        name="url" 
                        onChange={this.handleInput}
                        value={this.state.url}
                        label='Domain Url' />
                     </Box>

                     <Box className="BoxWidth">
                     <TextField id='outlined-basic' 
                        name="portal_url" 
                        onChange={this.handleInput}
                        value={this.state.portal_url}
                        label='Portal Url' />
                     </Box>

                     <Button
						variant="contained"
					    type="submit"
						className="SaveButton"
						startIcon={<DoneIcon />}>
						Update Domain Url
					</Button>
                    </form>
           			
									</Grid>
								</Grid>
                    </Card>            
                    </Grid>
            </Grid>            
        </Page>

      </>
       );
   }
}
export default DomainUrl;