/** @format */

import {
	searhDeviceInfoApi,
    getDeviceInfoAPI,
    deleteDeviceInfoAPI,
    postPlatStatusAPI,
	postPlatDeleteFileAPI,
    DeviceInfoNoteDateApi,
    DeviceNoteDateApi,
} from "../api";
import Instance from "../axois";

const API_URL = process.env.REACT_APP_API;

const getDevice = async (params = { page:0 }) => {
	try {
		const response = await Instance.get(API_URL + getDeviceInfoAPI, { params });
		return response.data;
	} catch (error) {
		throw error;
	}
};

const deleteSamikoDevice = async (id) => {
	try {
		const response = await Instance.get(API_URL + deleteDeviceInfoAPI + "/" + id);
		return response;
	} catch (error) {
		throw error;
	}
};

const getSearchFilter = async (data) => {
	try {
		const response = await Instance.get(API_URL + searhDeviceInfoApi + "/" + data);
		return response;
	} catch (error) {
		throw error;
	}
};

const postStatus = async (data,sId) => {
	try {
		const response = await Instance.post(API_URL + postPlatStatusAPI + "/" + sId, data);
		return response.data;
	} catch (error) {
		throw error;
	}
};

const postDate = async (id,data) => {
	try {
		  const response = await Instance.put(API_URL + DeviceInfoNoteDateApi +"/"+ id, data);
		  return response.data;
	  } catch (error) {
		  throw error;
	  }
  };

  const viewNoteDate = async (id) => {
	try {
		  const response = await Instance.get(API_URL + DeviceNoteDateApi +"/"+ id);
		  return response.data;
	  } catch (error) {
		  throw error;
	  }
  };  

  const deleteFile = async (id,data) => {
	try {
		const response = await Instance.post(API_URL + postPlatDeleteFileAPI +"/"+ id, data);
		return response;
	} catch (error) {
		throw error;
	}
};

export {
	getSearchFilter,
    deleteSamikoDevice,
    postStatus,
    getDevice,
    postDate,
	deleteFile,
    viewNoteDate
};