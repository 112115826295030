/** @format */

// component
import Iconify from "../../components/Iconify";
import Homeimg from "../../assets/images/home.svg";
import Productimg from "../../assets/images/products.svg";
import Rollimg from "../../assets/images/roll.svg";


// ----------------------------------------------------------------------

const getIcon = (name) => <img src={name} alt="a" />;

const navConfig = [
	{
		title: "Shahid K9"
	},
	{
		title: "Domain Url",
		icon: getIcon(Productimg),
		path: "/domain_url"
	},
	{
		title: "Device Info",
		icon: getIcon(Rollimg),
		path: "/device-info",
	},
	{
		title: "Contact Detail",
		icon: getIcon(Productimg),
		path: "/contact_detail"
	},
	{
		title: "Update Version",
		icon: getIcon(Productimg),
		path: "/update_version"
	},
	{
		title: "Shahid Amiko"
	},
	{
		title: "Domain Url",
		icon: getIcon(Productimg),
		path: "/amiko_domain_url"
	},
	{
		title: "Device Info",
		icon: getIcon(Rollimg),
		path: "/platinum-device-info"
	},
	{
		title: "Contact Detail",
		icon: getIcon(Productimg),
		path: "/amiko_contact_detail"
	},
	{
		title: "Update Version",
		icon: getIcon(Productimg),
		path: "/amiko_update_version"
	},
	{
		title: "Shahid Amiko 2022"
	},
	{
		title: "Domain Url",
		icon: getIcon(Productimg),
		path: "/Samiko_domain_url"
	},
	{
		title: "Device Info",
		icon: getIcon(Rollimg),
		path: "/Samiko_device_info"
	},
	{
		title: "Contact Detail",
		icon: getIcon(Productimg),
		path: "/Samiko_contact_detail"
	},
	{
		title: "Update Version",
		icon: getIcon(Productimg),
		path: "/Samiko_update_version"
	},
	{
		title: "Shahid Masa"
	},
	{
		title: "Domain Url",
		icon: getIcon(Productimg),
		path: "/shahid_masa_domain_url"
	},
	{
		title: "Device Info",
		icon: getIcon(Rollimg),
		path: "/shahid_masa_device_info"
	},
	{
		title: "Contact Detail",
		icon: getIcon(Productimg),
		path: "/shahid_masa_contact_detail"
	},
	{
		title: "Update Version",
		icon: getIcon(Productimg),
		path: "/shahid_masa_update_version"
	},
	{
		title: "SHTV"
	},
	{
		title: "Domain Url",
		icon: getIcon(Productimg),
		path: "/shtv_domain_url"
	},
	{
		title: "Device Info",
		icon: getIcon(Rollimg),
		path: "/shtv_device_info"
	},
	{
		title: "Contact Detail",
		icon: getIcon(Productimg),
		path: "/shtv_contact_detail"
	},
	{
		title: "Update Version",
		icon: getIcon(Productimg),
		path: "/shtv_update_version"
	}
];

export default navConfig;
