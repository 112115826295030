/** @format */

import {
	searhMasaDeviceInfoApi,
	searhSHTVDeviceInfoApi,
    getMasaDeviceInfoAPI,
	getSHTVDeviceInfoAPI,
    deleteMasaDeviceInfoAPI,
	deleteSHTVDeviceInfoAPI,
    postPlatStatusAPI,
	postPlatDeleteFileAPI,
    MasaDeviceInfoNoteDateApi,
	SHTVDeviceInfoNoteDateApi,
    MasaDeviceNoteDateApi,
	SHTVDeviceNoteDateApi
} from "../api";
import Instance from "../axois";

const API_URL = process.env.REACT_APP_API;

const getDevice = async (params = { page:0 }) => {
	try {
		const response = await Instance.get(API_URL + getMasaDeviceInfoAPI, { params });
		return response.data;
	} catch (error) {
		throw error;
	}
};

const getshtvDevice = async (params = { page:0 }) => {
	try {
		const response = await Instance.get(API_URL + getSHTVDeviceInfoAPI, { params });
		return response.data;
	} catch (error) {
		throw error;
	}
};


const deleteSamikoDevice = async (id) => {
	try {
		const response = await Instance.get(API_URL + deleteMasaDeviceInfoAPI + "/" + id);
		return response;
	} catch (error) {
		throw error;
	}
};


const deleteshtvDevice = async (id) => {
	try {
		const response = await Instance.get(API_URL + deleteSHTVDeviceInfoAPI + "/" + id);
		return response;
	} catch (error) {
		throw error;
	}
};

const getSearchFilter = async (data) => {
	try {
		const response = await Instance.get(API_URL + searhMasaDeviceInfoApi + "/" + data);
		return response;
	} catch (error) {
		throw error;
	}
};

const getshtvSearchFilter = async (data) => {
	try {
		const response = await Instance.get(API_URL + searhSHTVDeviceInfoApi + "/" + data);
		return response;
	} catch (error) {
		throw error;
	}
};

const postStatus = async (data,sId) => {
	try {
		const response = await Instance.post(API_URL + postPlatStatusAPI + "/" + sId, data);
		return response.data;
	} catch (error) {
		throw error;
	}
};

const postDate = async (id,data) => {
	try {
		  const response = await Instance.put(API_URL + MasaDeviceInfoNoteDateApi +"/"+ id, data);
		  return response.data;
	  } catch (error) {
		  throw error;
	  }
  };


  const shtvpostDate = async (id,data) => {
	try {
		  const response = await Instance.put(API_URL + SHTVDeviceInfoNoteDateApi +"/"+ id, data);
		  return response.data;
	  } catch (error) {
		  throw error;
	  }
  };


  const viewNoteDate = async (id) => {
	try {
		  const response = await Instance.get(API_URL + MasaDeviceNoteDateApi +"/"+ id);
		  return response.data;
	  } catch (error) {
		  throw error;
	  }
  };  


  const viewshtvNoteDate = async (id) => {
	try {
		  const response = await Instance.get(API_URL + SHTVDeviceNoteDateApi +"/"+ id);
		  return response.data;
	  } catch (error) {
		  throw error;
	  }
  };  

  const deleteFile = async (id,data) => {
	try {
		const response = await Instance.post(API_URL + postPlatDeleteFileAPI +"/"+ id, data);
		return response;
	} catch (error) {
		throw error;
	}
};

export {
	getSearchFilter,
	getshtvSearchFilter,
    deleteSamikoDevice,
	deleteshtvDevice,
    postStatus,
    getDevice,
	getshtvDevice,
    postDate,
	shtvpostDate,
	deleteFile,
    viewNoteDate,
    viewshtvNoteDate,
	
};